// Color variables
$color-white: #ffffff;
$color-whiteDark: #f4f4f4;
$color-whiteDarkest: #ededed;
$color-grayLightest: #d1d1d1;
$color-grayLight: #adadad;
$color-gray: #999999;
$color-grayDark: #666666;
$color-blackLight: #333333;
$color-black: #1f1f1f;

$color-brown: #583f35;

$color-error: #eb5757;
$color-warning: #ff8200;
$color-blue: #2baadf;
$color-muted: #8c8c8c;
$color-disabled: #bac3d1;
$color-black-text: #1a1d1e;
$color-rate-star-yellow: #fadb14;

// Screen size variables
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md-break: 767px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

// Spaces
$spacer: 8px;

$spacer-0: $spacer * 0;
$spacer-1: $spacer / 4;
$spacer-2: $spacer / 2;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 2;
$spacer-6: $spacer * 2.5;
$spacer-7: $spacer * 3;
$spacer-8: $spacer * 3.5;
$spacer-9: $spacer * 4;
$spacer-10: $spacer * 4.5;
$spacer-11: $spacer * 5;
$spacer-12: $spacer * 5.5;
$spacer-13: $spacer * 6;

// Font size variables
$fontSize-xxxs: 12px;
$fontSize-xxs: 14px;
$fontSize-xs: 16px;
$fontSize-sm: 18px;
$fontSize-md: 20px;
$fontSize-lg: 24px;
$fontSize-xlg: 26px;
$fontSize-xxlg: 36px;

// Header
$header-height: 48px;
$header-height-franchisee: 60px;
$header-padding-side: 92px;
$menu-item-width-min: 96px;
$header-logo-height: 30px;

$content-desktop-width: 1078px;

$btn-opacity-height: 36px;
$btn-color-opacity: #eeeeee80;

//Page
$page-width-min: 1024px;
$page-height-min: 768px;

// Font family variables
$fontFamily-default: 'Roboto';
$fontFamily-medium: 'Roboto Medium';
$fontFamily-semiBold: 'Roboto SemiBold';
$fontFamily-bold: 'Roboto Bold';
$fontFamily-UniformRounded: 'Uniform Rounded';

// Modal
$form-modal-width: 640px;
$common-modal-width: 340px;

// Border radius
$border-radius-button: 4px;
$border-radius: 6px;

//Form
$form-error-field-height: 18px;

//Layout
$layout-margin-top: 85px;

// Image uploader
$img-upload-size: 240px;
