@import '../abstracts/variables';

.basic {
  &__desktop-wrapper {
    max-width: $content-desktop-width;
    padding-left: $spacer-7;
    padding-right: $spacer-7;
    margin: 0 auto;
  }
  &__link {
    color: $color-blue !important;
    text-decoration: underline !important;
  }
  &__layout {
    &_franchisor {
      margin-top: $header-height;
      width: 100%;
      min-width: $page-width-min;
      min-height: $page-height-min;
    }
    &_franchisee {
      margin-top: $header-height-franchisee;
      width: 100%;
    }
  }
  &__text {
    &_title {
      font-family: $fontFamily-default !important;
      font-size: $fontSize-md !important;
      font-weight: 500 !important;
    }
    &_paragraph {
      font-family: $fontFamily-default !important;
      font-size: $fontSize-xxs !important;
      font-weight: 400 !important;
    }
  }
  &__layout_m-t {
    margin-top: $layout-margin-top;
  }
}

ul,
li {
  list-style-type: none !important;
}

.box-with-corner-border {
  --b: 4px; /* thickness of the border */
  --c: #f4831f; /* color of the border */
  --w: 20px; /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  border-radius: 6px;
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
}
